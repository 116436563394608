import { ASSET_ADDRESS_NATIVE, chains } from 'pages/Bridge/const'
import { Asset } from 'pages/Bridge/types'

// See https://github.com/ChainAgnostic/CAIPs/blob/master/CAIPs/caip-19.md
export const getCiap19AssetId = (asset: Asset) => {
  const chainId = chains[asset.network].chainId
  const isNative = asset.assetAddress === ASSET_ADDRESS_NATIVE
  return `eip155:${chainId}${isNative ? '' : `/erc20:${asset.assetAddress}`}`
}

export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const trimDecimals = (string: string) => {
  return string.replace(/(\.[0-9]{0,6})[0-9]*$/, '$1').replace(/\.0$/, '')
}

/**
 * Convert float string value into uint value (given decimals) without throwing exceptions.
 */
export const floatToUIntSafe = (
  string: string,
  { decimals = 18 }: { decimals?: number } = {},
): { error?: boolean; value: bigint } => {
  const [intPart, decimalPart, ...rest] = string.split('.')
  if (rest.length || decimalPart?.length > decimals) {
    return { error: true, value: 0n }
  }
  try {
    const int = BigInt(intPart || '0') * BigInt(10) ** BigInt(decimals)
    const decimal = BigInt(decimalPart?.padEnd(Number(decimals), '0') || '0')
    return {
      value: int + decimal,
    }
  } catch (e) {
    return { error: true, value: 0n }
  }
}

export const shortenHex = (hex: string) => {
  if (hex.length <= 10) {
    return hex
  }
  return `${hex.slice(0, 6)}..${hex.slice(-4)}`
}

export const getL0ScanLinkForTxHash = async (txHash: string) => {
  return `https://layerzeroscan.com/tx/${txHash}`
}
