import { useCallback, useEffect, useMemo, useState } from 'react'
import { Contract, ethers } from 'ethers'
import { useAccount, useConnect, useFeeData, useSwitchNetwork } from 'wagmi'
import { getWalletClient } from 'wagmi/actions'

import {
  Absolute,
  Avatar,
  Box,
  Button,
  Copyable,
  HStack,
  Layout,
  Link,
  Spacer,
  Text,
  TextWidget,
  useStatusPopup,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import * as Icons from '@revolut/icons'
import { getSwapTransaction } from 'pages/Bridge/utils'
import { chains } from 'pages/Bridge/const'
import { Asset } from 'pages/Bridge/types'
import {
  floatToUIntSafe,
  getL0ScanLinkForTxHash,
  shortenHex,
  trimDecimals,
} from 'pages/Bridge/utils/misc'
import { tokenAbi } from 'pages/Bridge/const/contracts/tokenAbi'
import { AssetSelect } from './AssetSelect'

type GetSwapTransactionData = Awaited<ReturnType<typeof getSwapTransaction>>
const getSwapTransactionPlaceholderData: GetSwapTransactionData = {
  unsignedTx: {
    to: '',
    data: '',
    chainId: 0n,
    nonce: 0,
    value: 0n,
    gasLimit: 0n,
    gasPrice: 0n,
  },
  feeInCoin: 0n,
  amountOut: 0n,
  sendArgs: [],
  gasEstimate: 0n,
}

const INITIAL_TEXT_WIDGET_TEXT = 'Please connect your wallet.'

export const BridgeWidget = ({ assets }: { assets: Asset[] }) => {
  const [sourceAsset, setSourceAsset] = useState(assets[0])
  const [sourceValue, setSourceValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [textWidgetText, setTextWidgetText] = useState(
    INITIAL_TEXT_WIDGET_TEXT as React.ReactNode,
  )
  const sourceChain = chains[sourceAsset.network]
  const destinationAssets = useMemo(() => {
    return assets.filter(
      asset => asset !== sourceAsset && asset.symbol === sourceAsset.symbol,
    )
  }, [assets, sourceAsset])
  const [destinationAsset, setDestinationAsset] = useState(destinationAssets[0])
  const [swapData, setSwapData] = useState(
    getSwapTransactionPlaceholderData as GetSwapTransactionData,
  )
  const { address } = useAccount()
  const { data: networkFeeData } = useFeeData()
  const statusPopup = useStatusPopup()
  const { error: chainSwitchError, switchNetwork } = useSwitchNetwork()

  const { error: sourceValueParseError, value: sourceValueParsed } = floatToUIntSafe(
    sourceValue,
    {
      decimals: sourceAsset.decimals,
    },
  )

  const [ethSigner, setEthSigner] = useState<ethers.Signer | null>(null)

  const { isConnected } = useAccount()

  useEffect(() => {
    const getSigner = async () => {
      if (!isConnected) {
        return
      }

      // Poll for wallet client
      let attempts = 0
      const maxAttempts = 10

      const tryGetWalletClient = async () => {
        const walletClient = await getWalletClient({
          chainId: sourceChain.chainId,
        })

        if (walletClient) {
          const signer = await new ethers.BrowserProvider(walletClient).getSigner()
          setEthSigner(signer)
          return true
        }

        attempts += 1
        if (attempts >= maxAttempts) {
          return false
        }

        return new Promise(resolve => {
          setTimeout(() => {
            resolve(tryGetWalletClient())
          }, 1000)
        })
      }

      tryGetWalletClient()
    }
    getSigner()
  }, [sourceChain.chainId, isConnected])

  const tokenContract = useMemo(
    () =>
      ethSigner ? new Contract(sourceAsset.assetAddress, tokenAbi, ethSigner) : null,
    [sourceAsset.assetAddress, ethSigner],
  )

  const getSubmittedTransactionMessage = useMemo(
    () =>
      (tx: { hash: string }, success = false, l0TxHashLink = '') => {
        return (
          <Text>
            Transaction was {success ? 'MINED' : 'submitted'}, check it{' '}
            <Link
              target="_blank"
              href={`${chains[sourceAsset.network].explorer}/tx/${tx.hash}`}
            >
              here
            </Link>
            .{' '}
            {l0TxHashLink && (
              <Link target="_blank" href={l0TxHashLink}>
                Check cross-chain transaction here
              </Link>
            )}
          </Text>
        )
      },
    [sourceAsset],
  )

  if (address && textWidgetText === INITIAL_TEXT_WIDGET_TEXT) {
    setTextWidgetText(
      <HStack>
        <Text>Wallet connected</Text>
        <Spacer />
        <Text variant="secondary">{shortenHex(address)}</Text>
      </HStack>,
    )
  }

  useEffect(() => {
    if (chainSwitchError) {
      setTextWidgetText(`Error switching networks: ${chainSwitchError}`)
    }
  }, [chainSwitchError])

  useEffect(() => {
    if (sourceChain) {
      switchNetwork?.(sourceChain.chainId)
    }
  }, [sourceChain, switchNetwork])

  // Update destination asset if it becomes the same as source or invalid
  useEffect(() => {
    if (!destinationAssets.includes(destinationAsset)) {
      setDestinationAsset(destinationAssets[0])
    }
  }, [destinationAsset, destinationAssets])

  useEffect(() => {
    ;(async () => {
      setSwapData(getSwapTransactionPlaceholderData)

      if (!address || !ethSigner || sourceValueParseError) {
        return
      }

      if (!(sourceValueParsed > 0n)) {
        return
      }

      setLoading(true)
      if (!ethSigner) {
        setTextWidgetText('Please wait for wallet connection...')
        return
      }

      try {
        const newSwapData = await getSwapTransaction({
          owner: { address },
          network: sourceAsset.network,
          signer: ethSigner,
          taskArgs: {
            amount: sourceValueParsed,
            tokenAddress: sourceAsset.assetAddress,
            destinationAddress: address,
            targetNetwork: destinationAsset.network,
          },
        })

        if (!newSwapData.gasEstimate) {
          setTextWidgetText('Error retrieving gas estimate')
          return
        }

        setTextWidgetText(
          <>
            <HStack>
              <Text>Bridge fee</Text>
              <Spacer />
              <Text variant="secondary">
                ~{' '}
                {`${trimDecimals(
                  ethers.formatUnits(
                    newSwapData.feeInCoin,
                    chains[sourceAsset.network].baseAsset.decimals,
                  ),
                )} ${chains[sourceAsset.network].baseAsset.symbol}`}
              </Text>
            </HStack>
            <HStack>
              <Text>Transaction fee</Text>
              <Spacer />
              <Text variant="secondary">
                {networkFeeData?.gasPrice && BigInt(newSwapData.gasEstimate) > 0n
                  ? `~ ${trimDecimals(
                      ethers.formatUnits(
                        BigInt(newSwapData.gasEstimate) * networkFeeData.gasPrice,
                        chains[sourceAsset.network].baseAsset.decimals,
                      ),
                    )} ${chains[sourceAsset.network].baseAsset.symbol}`
                  : '⚠️ unable to estimate'}
              </Text>
            </HStack>
          </>,
        )
        setSwapData(newSwapData)
      } catch (e: any) {
        console.error(e.message)
        setTextWidgetText(
          `⚠️ Unable to prepare swap transaction. ${
            e.message || e
          }. See console for more details.`,
        )
      } finally {
        setLoading(false)
      }
    })()
  }, [
    sourceAsset,
    destinationAsset,
    ethSigner,
    address,
    sourceValue,
    statusPopup,
    networkFeeData,
    sourceValueParseError,
    sourceValueParsed,
  ])

  const swapTokens = useCallback(async () => {
    if (
      !address ||
      !ethSigner ||
      sourceValueParseError ||
      loading ||
      !tokenContract ||
      !swapData.gasEstimate
    ) {
      return
    }

    setLoading(true)
    try {
      const tx = await tokenContract.send(...swapData.sendArgs)
      setTextWidgetText(getSubmittedTransactionMessage(tx))
      await tx.wait()
      setTextWidgetText(
        getSubmittedTransactionMessage(tx, true, await getL0ScanLinkForTxHash(tx.hash)),
      )
    } catch (e: any) {
      console.error('Transaction error:', e)
      setTextWidgetText(
        `⚠️ Transaction failed: ${e.message || e}. See console for more details.`,
      )
    } finally {
      setLoading(false)
    }
  }, [
    ethSigner,
    address,
    sourceValueParseError,
    loading,
    tokenContract,
    swapData,
    getSubmittedTransactionMessage,
  ])

  // Auto-connect
  const { connect, connectors } = useConnect()
  useEffect(() => {
    if (connectors.length > 0) {
      connect({ connector: connectors[0] })
    }
  }, [connect, connectors])

  // Switch destination asset to the first available asset once the pair becomes invalid
  useEffect(() => {
    if (!destinationAssets.includes(destinationAsset)) {
      setDestinationAsset(destinationAssets[0])
    }
  }, [destinationAssets, destinationAsset])

  return (
    <Layout>
      <Layout.Main>
        <Widget overflow="hidden">
          <VStack space="s-16" margin="s-16">
            <Box>
              <Text variant="h1" use="h1">
                Bridge • Swap {sourceAsset.symbol}
              </Text>
              <Text use="p" variant="small">
                Open this page in your wallet&apos;s embedded browser to swap and bridge
                assets.{' '}
              </Text>
              <Absolute bottom={0} right={0}>
                {address ? (
                  <Text use="div" color="green" variant="small">
                    <Copyable value={address} labelButtonCopy="Copy">
                      Copy wallet
                    </Copyable>
                    <Box>&nbsp;✔ wallet connected&nbsp;</Box>
                  </Text>
                ) : (
                  <Text use="span" color="yellow" variant="small">
                    &nbsp;✘ not connected&nbsp;
                  </Text>
                )}
              </Absolute>
            </Box>
            <AssetSelect
              value={sourceValue}
              onValueChange={setSourceValue}
              assets={assets}
              selectedAsset={sourceAsset}
              fee={0n}
              onAssetSelect={setSourceAsset}
            />
            <Box alignSelf="center" height="0">
              <Avatar
                color="transparent-grey-100"
                useIcon={Icons.ArrowDown}
                marginTop="-s-40"
              />
            </Box>
            <AssetSelect
              disabled
              value={ethers.formatUnits(
                swapData.amountOut || 0n,
                destinationAsset.decimals,
              )}
              assets={destinationAssets}
              selectedAsset={destinationAsset}
              onAssetSelect={setDestinationAsset}
            />
            <TextWidget>
              <TextWidget.Content>{textWidgetText}</TextWidget.Content>
            </TextWidget>
            <Box>
              <Button type="button" pending={loading} onClick={swapTokens}>
                Swap!
              </Button>
            </Box>
          </VStack>
        </Widget>
      </Layout.Main>
    </Layout>
  )
}
