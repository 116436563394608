import { Asset, NetworkName } from 'pages/Bridge/types'
import {
  BASE_ASSET_ARBITRUM,
  BASE_ASSET_AVALANCHE,
  BASE_ASSET_ETHEREUM,
  BASE_ASSET_OPTIMISM,
  BASE_ASSET_POLYGON,
} from './assets'

export const MAX_UINT256 = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export const chains: {
  [key in NetworkName]: {
    chainId: number
    explorer: string
    rpc: string
    baseAsset: Asset
  }
} = {
  optimism: {
    chainId: 10,
    explorer: 'https://optimistic.etherscan.io',
    rpc: 'https://rpc.ankr.com/optimism',
    baseAsset: BASE_ASSET_OPTIMISM,
  },
  arbitrum: {
    chainId: 42161,
    explorer: 'https://arbiscan.io',
    rpc: 'https://arbitrum.public-rpc.com/',
    baseAsset: BASE_ASSET_ARBITRUM,
  },
  polygon: {
    chainId: 137,
    explorer: 'https://polygonscan.com',
    rpc: 'https://polygon-rpc.com/',
    baseAsset: BASE_ASSET_POLYGON,
  },
  ethereum: {
    chainId: 1,
    explorer: 'https://etherscan.io',
    rpc: 'https://eth.public-rpc.com/',
    baseAsset: BASE_ASSET_ETHEREUM,
  },
  avalanche: {
    chainId: 43114,
    explorer: 'https://snowtrace.io',
    rpc: 'https://avalanche-c-chain-rpc.publicnode.com',
    baseAsset: BASE_ASSET_AVALANCHE,
  },
  'ethereum-sepolia': {
    chainId: 11155111,
    explorer: 'https://sepolia.etherscan.io',
    rpc: 'https://ethereum-sepolia-rpc.publicnode.com',
    baseAsset: BASE_ASSET_ETHEREUM,
  },
  'polygon-amoy': {
    chainId: 80002,
    explorer: 'https://amoy.polygonscan.com',
    rpc: 'https://polygon-amoy-bor-rpc.publicnode.com',
    baseAsset: BASE_ASSET_POLYGON,
  },
  'avalanche-fuji': {
    chainId: 43113,
    explorer: 'https://testnet.snowtrace.io/',
    rpc: 'https://avalanche-fuji-c-chain-rpc.publicnode.com\t',
    baseAsset: BASE_ASSET_AVALANCHE,
  },
  'arbitrum-sepolia': {
    chainId: 421614,
    explorer: 'https://sepolia.arbiscan.io/',
    rpc: 'https://arbitrum-sepolia-rpc.publicnode.com\t',
    baseAsset: BASE_ASSET_ARBITRUM,
  },
}
