import { useMemo } from 'react'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { Chain, configureChains, createConfig, WagmiConfig } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import {
  mainnet,
  polygon,
  arbitrum,
  optimism,
  sepolia,
  avalancheFuji,
} from 'wagmi/chains'
import { CurrencyProvider } from '@revolut/ui-kit'
import { chains as supportedChains } from './const/chains'
import { BridgeWidget } from './Components/BridgeWidget'
import { assets } from './const'

const arbitrumSepolia: Chain = {
  id: 421_614,
  name: 'Arbitrum Sepolia',
  network: 'arbitrum-sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ['https://arbitrum-sepolia-rpc.publicnode.com'] },
    default: { http: ['https://arbitrum-sepolia-rpc.publicnode.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'Arbiscan', url: 'https://sepolia.arbiscan.io/' },
    default: { name: 'Arbiscan', url: 'https://sepolia.arbiscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
}
const polygonAmoy: Chain = {
  id: 80_002,
  name: 'Polygon Amoy',
  network: 'polygon-amoy',
  nativeCurrency: {
    decimals: 18,
    name: 'POL',
    symbol: 'POL',
  },
  rpcUrls: {
    public: { http: ['https://polygon-amoy-bor-rpc.publicnode.com\t'] },
    default: { http: ['https://polygon-amoy-bor-rpc.publicnode.com\t'] },
  },
  blockExplorers: {
    etherscan: { name: 'Polygonscan', url: 'https://amoy.polygonscan.com//' },
    default: { name: 'Polygonscan', url: 'https://amoy.polygonscan.com//' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
}

const provider = jsonRpcProvider({
  rpc: chain => ({
    http: Object.values(supportedChains).find(c => c.chainId === chain.id)!.rpc,
  }),
})

const chains = [
  mainnet,
  polygon,
  arbitrum,
  optimism,
  sepolia,
  avalancheFuji,
  arbitrumSepolia,
  polygonAmoy,
]
const { publicClient } = configureChains(chains, [provider])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
      },
    }),
  ],
  publicClient,
})

export const Bridge = ({ testnet }: { testnet: boolean }) => {
  const filteredAssets = assets.filter(asset => testnet === asset.testnet)
  const currencies = useMemo(
    () =>
      filteredAssets.map(({ symbol, decimals }) => ({
        code: symbol,
        symbol,
        fractionalPart: decimals,
      })),
    [filteredAssets],
  )

  return (
    <WagmiConfig config={wagmiConfig}>
      <CurrencyProvider currencies={currencies}>
        <BridgeWidget assets={filteredAssets} />
      </CurrencyProvider>
    </WagmiConfig>
  )
}
