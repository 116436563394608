import { ASSET_ADDRESS_NATIVE, chains } from 'pages/Bridge/const'
import { Asset } from 'pages/Bridge/types'
import { useBalance, useAccount } from 'wagmi'

export const useAssetBalance = (asset: Asset) => {
  const { address, isConnecting, isDisconnected } = useAccount()
  const chainId = chains[asset.network].chainId
  const { data, isError, isLoading } = useBalance({
    address,
    ...(asset.assetAddress === ASSET_ADDRESS_NATIVE ? {} : { token: asset.assetAddress }),
    chainId,
  })

  return { balance: data, isConnecting, isDisconnected, isError, isLoading }
}
