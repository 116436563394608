import { Asset } from 'pages/Bridge/types'

export const ASSET_ADDRESS_NATIVE = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
export const BASE_ASSET_POLYGON: Asset = {
  network: 'polygon',
  assetAddress: ASSET_ADDRESS_NATIVE,
  symbol: 'POL',
  decimals: 18,
  testnet: false,
}
export const BASE_ASSET_ARBITRUM: Asset = {
  network: 'arbitrum',
  assetAddress: ASSET_ADDRESS_NATIVE,
  symbol: 'ETH',
  decimals: 18,
  testnet: false,
}
export const BASE_ASSET_OPTIMISM: Asset = {
  network: 'optimism',
  assetAddress: ASSET_ADDRESS_NATIVE,
  symbol: 'ETH',
  decimals: 18,
  testnet: false,
}
export const BASE_ASSET_ETHEREUM: Asset = {
  network: 'ethereum',
  assetAddress: ASSET_ADDRESS_NATIVE,
  symbol: 'ETH',
  decimals: 18,
  testnet: false,
}

export const BASE_ASSET_AVALANCHE: Asset = {
  network: 'avalanche',
  assetAddress: ASSET_ADDRESS_NATIVE,
  symbol: 'AVAX',
  decimals: 18,
  testnet: false,
}

const EURR: Array<Asset> = [
  {
    network: 'ethereum-sepolia',
    assetAddress: '0x9Ce81cE5B8ebe99F8160E2E44E599602064bbff3',
    symbol: 'EURR',
    decimals: 6,
    testnet: true,
  },
  {
    network: 'avalanche-fuji',
    assetAddress: '0x9Ce81cE5B8ebe99F8160E2E44E599602064bbff3',
    symbol: 'EURR',
    decimals: 6,
    testnet: true,
  },
  {
    network: 'polygon-amoy',
    assetAddress: '0x9Ce81cE5B8ebe99F8160E2E44E599602064bbff3',
    symbol: 'EURR',
    decimals: 6,
    testnet: true,
  },
  {
    network: 'arbitrum-sepolia',
    assetAddress: '0x9Ce81cE5B8ebe99F8160E2E44E599602064bbff3',
    symbol: 'EURR',
    decimals: 6,
    testnet: true,
  },

  {
    network: 'ethereum',
    assetAddress: '0x6174131F4f768FED31B6611e9eb68FE4e6241C38',
    symbol: 'EURR',
    decimals: 6,
    testnet: false,
  },
  {
    network: 'polygon',
    assetAddress: '0x6174131F4f768FED31B6611e9eb68FE4e6241C38',
    symbol: 'EURR',
    decimals: 6,
    testnet: false,
  },
  {
    network: 'arbitrum',
    assetAddress: '0x6174131F4f768FED31B6611e9eb68FE4e6241C38',
    symbol: 'EURR',
    decimals: 6,
    testnet: false,
  },
]

const USDR: Array<Asset> = [
  {
    network: 'ethereum-sepolia',
    assetAddress: '0x37117203F6E549e8602783b098A4b6660fCB261b',
    symbol: 'USDR',
    decimals: 6,
    testnet: true,
  },
  {
    network: 'avalanche-fuji',
    assetAddress: '0x37117203F6E549e8602783b098A4b6660fCB261b',
    symbol: 'USDR',
    decimals: 6,
    testnet: true,
  },
  {
    network: 'polygon-amoy',
    assetAddress: '0x37117203F6E549e8602783b098A4b6660fCB261b',
    symbol: 'USDR',
    decimals: 6,
    testnet: true,
  },
  {
    network: 'arbitrum-sepolia',
    assetAddress: '0x37117203F6E549e8602783b098A4b6660fCB261b',
    symbol: 'USDR',
    decimals: 6,
    testnet: true,
  },

  {
    network: 'ethereum',
    assetAddress: '0x5F784EF60E24dd1768f3d63D49c1011C9cc2b489',
    symbol: 'USDR',
    decimals: 6,
    testnet: false,
  },
  {
    network: 'polygon',
    assetAddress: '0x5F784EF60E24dd1768f3d63D49c1011C9cc2b489',
    symbol: 'USDR',
    decimals: 6,
    testnet: false,
  },
  {
    network: 'arbitrum',
    assetAddress: '0x5F784EF60E24dd1768f3d63D49c1011C9cc2b489',
    symbol: 'USDR',
    decimals: 6,
    testnet: false,
  },
]

const GBPR: Array<Asset> = [
  {
    network: 'ethereum-sepolia',
    assetAddress: '0xf6469b072e6Bf20e3ef4FC4dD165F29bcF077cd1',
    symbol: 'GBPR',
    decimals: 6,
    testnet: true,
  },
  {
    network: 'avalanche-fuji',
    assetAddress: '0xf6469b072e6Bf20e3ef4FC4dD165F29bcF077cd1',
    symbol: 'GBPR',
    decimals: 6,
    testnet: true,
  },
  {
    network: 'polygon-amoy',
    assetAddress: '0xf6469b072e6Bf20e3ef4FC4dD165F29bcF077cd1',
    symbol: 'GBPR',
    decimals: 6,
    testnet: true,
  },
  {
    network: 'arbitrum-sepolia',
    assetAddress: '0xf6469b072e6Bf20e3ef4FC4dD165F29bcF077cd1',
    symbol: 'GBPR',
    decimals: 6,
    testnet: true,
  },

  {
    network: 'ethereum',
    assetAddress: '0x2c24e2549e7d559CB10ad8A48F3e134931ad7460',
    symbol: 'GBPR',
    decimals: 6,
    testnet: false,
  },
  {
    network: 'polygon',
    assetAddress: '0x2c24e2549e7d559CB10ad8A48F3e134931ad7460',
    symbol: 'GBPR',
    decimals: 6,
    testnet: false,
  },
  {
    network: 'arbitrum',
    assetAddress: '0x2c24e2549e7d559CB10ad8A48F3e134931ad7460',
    symbol: 'GBPR',
    decimals: 6,
    testnet: false,
  },
]

export const assets: Array<Asset> = [...EURR, ...USDR, ...GBPR]
