import { Amount, Text, HStack, IconButton, Box, Dropdown } from '@revolut/ui-kit'
import * as Icons from '@revolut/icons'
import { Asset } from 'pages/Bridge/types'
import { useRef, useState } from 'react'
import {
  capitalize,
  getCiap19AssetId,
  floatToUIntSafe,
  trimDecimals,
} from 'pages/Bridge/utils/misc'
import { useAssetBalance } from 'pages/Bridge/utils'
import { ethers } from 'ethers'

export const AssetSelect = ({
  value,
  assets,
  selectedAsset,
  fee,
  onAssetSelect,
  onValueChange,
  disabled,
}: {
  value?: string
  assets: Asset[]
  selectedAsset: Asset
  fee?: bigint
  onAssetSelect?: (asset: Asset) => unknown
  onValueChange?: (value: string) => unknown
  allowance?: bigint
  disabled?: boolean
}) => {
  let error = ''
  const dropdownAnchorRef = useRef(null)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const { balance } = useAssetBalance(selectedAsset)
  const { error: inputParseError, value: inputParsedValue } = floatToUIntSafe(
    value || '',
    {
      decimals: selectedAsset.decimals,
    },
  )
  if (!disabled && inputParseError) {
    error = 'Invalid number supplied'
  }
  if (!disabled && balance && balance.value < inputParsedValue) {
    error = 'Insufficient balance'
  }

  return (
    <Box ref={dropdownAnchorRef}>
      <Amount use="label">
        <Amount.Currency
          aria-label="Currency"
          value={selectedAsset.symbol}
          message={`Balance on ${capitalize(selectedAsset.network)}: ${trimDecimals(
            balance?.formatted || '?',
          )}`}
          onClick={() => setOpen(true)}
        />
        <Amount.Input
          aria-label="Amount input"
          value={value}
          errorMessage={error}
          aria-invalid={!!error}
          onChange={event => onValueChange?.(event.currentTarget.value)}
          description={
            fee && (
              <HStack align="center" space="s-8">
                <IconButton
                  useIcon={Icons.InfoOutline}
                  size={16}
                  title="See detailed fee information"
                />
                <Text>{ethers.formatUnits(fee, selectedAsset.decimals)} fee</Text>
              </HStack>
            )
          }
        />
        <Dropdown
          disabled={disabled}
          open={open}
          anchorRef={dropdownAnchorRef}
          onClose={() => setOpen(false)}
          width={160}
          focusTrap
          closeOnEsc
          autoClose
        >
          <Dropdown.Group sticky="top">
            <Dropdown.Search
              placeholder="Search..."
              autoFocus
              value={search}
              onChange={e => setSearch(e.currentTarget.value)}
            />
          </Dropdown.Group>
          <Dropdown.Group>
            {assets
              .filter(
                asset =>
                  asset.symbol.toLowerCase().startsWith(search.toLowerCase()) ||
                  asset.network.toLowerCase().startsWith(search.toLowerCase()),
              )
              .map(asset => (
                <Dropdown.Item
                  key={getCiap19AssetId(asset)}
                  use="button"
                  onClick={() => {
                    onAssetSelect?.(asset)
                    onValueChange?.('')
                  }}
                >
                  <Text variant="primary" use="p">
                    {asset.symbol}
                  </Text>
                  <Text use="p" variant="small">
                    {asset.network}
                  </Text>
                </Dropdown.Item>
              ))}
          </Dropdown.Group>
        </Dropdown>
      </Amount>
    </Box>
  )
}
