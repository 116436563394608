import { Switch, Route, Redirect } from 'react-router-dom'
import { WalletConnect } from 'pages/WalletConnect'
import { ROUTES } from 'consts'
import { Bridge } from 'pages/Bridge'

export const Routes = () => {
  return (
    <Switch>
      <Route path={ROUTES.WalletConnect}>
        <WalletConnect />
      </Route>
      <Route path={ROUTES.Bridge}>
        <Bridge testnet={false} />
      </Route>
      <Route path={ROUTES.TestnetBridge}>
        <Bridge testnet />
      </Route>
      <Route>
        <Redirect to={ROUTES.WalletConnect} />
      </Route>
    </Switch>
  )
}
