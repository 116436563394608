import { Contract, ethers, Signer } from 'ethers'
import { Options } from '@layerzerolabs/lz-v2-utilities'
import { tokenAbi } from 'pages/Bridge/const'

type NetworkName =
  | 'ethereum'
  | 'polygon'
  | 'arbitrum'
  | 'optimism'
  | 'avalanche'
  | 'ethereum-sepolia'
  | 'polygon-amoy'
  | 'avalanche-fuji'
  | 'arbitrum-sepolia'

const ENDPOINT_ID = {
  ethereum: 30101,
  polygon: 30109,
  arbitrum: 30110,
  optimism: 30111,
  avalanche: 30106,

  'ethereum-sepolia': 40161,
  'avalanche-fuji': 40106,
  'polygon-amoy': 40267,
  'arbitrum-sepolia': 40231,
}

export const getSwapTransaction = async ({
  owner,
  network,
  taskArgs,
  signer,
}: {
  owner: { address: string }
  network: NetworkName
  taskArgs: {
    tokenAddress: string
    destinationAddress: string
    amount: bigint
    targetNetwork: NetworkName
  }
  signer: Signer
}) => {
  const tokenContract = new Contract(taskArgs.tokenAddress, tokenAbi, signer)

  const options = Options.newOptions().addExecutorLzReceiveOption(200000, 0).toBytes()

  const sendParam = [
    ENDPOINT_ID[taskArgs.targetNetwork],
    ethers.zeroPadValue(taskArgs.destinationAddress, 32),
    taskArgs.amount,
    taskArgs.amount,
    options,
    new Uint8Array(),
    new Uint8Array(),
  ]

  const [nativeFee] = await tokenContract.quoteSend(sendParam, false)

  const sendArgs = [
    sendParam,
    [nativeFee, 0],
    owner.address,
    { value: nativeFee, from: owner.address },
  ]

  const [unsignedTx, gasEstimate] = await Promise.all([
    tokenContract.send.populateTransaction(...sendArgs),
    (async () => {
      try {
        return await tokenContract.send.estimateGas(...sendArgs)
      } catch (e: any) {
        console.error(e)
        return 0n
      }
    })(),
  ])

  return {
    unsignedTx,
    feeInCoin: nativeFee,
    amountOut: taskArgs.amount || 0n,
    sendArgs,
    gasEstimate,
  }
}
